"use strict";
import { gsap } from "gsap";
console.log(gsap.version);

//date

let date = new Date().getFullYear();
document.getElementById('annee').innerHTML = date;





//slider index

const sliders = document.querySelectorAll(".slider");
for(let slider of sliders){
    console.log(slider);
}



      //suite slider

            let buttons = document.querySelectorAll(".slider__js");
            for(let button of buttons){
                button.addEventListener("click", (e)=>{
                    let tabs = document.querySelectorAll(".slider__switch");
                    for(let tab of tabs){
                        tab.classList.add("slider--hidden", "hidden");
                        tab.classList.remove("slider--show");
                        
                    }
                   
                    let tabId = e.currentTarget.getAttribute("data-tab");
                    let currentTab = document.getElementById(tabId);
                    currentTab.classList.remove("slider--hidden", "hidden");
                    currentTab.classList.add("slider--show");
                    
        
                    
                });

                
            }


            

           

            let voiture = document.querySelector('.illu__voiture');

            if  ((voiture)) {
                const Pcpvoiture = gsap.timeline({repeat:-1, repeatDelay: 10});
            
               
                    Pcpvoiture.fromTo("#VoitureFilet01", 
                        { opacity: 0, },
                        { duration: 0.7, opacity: 1 })
                
                    Pcpvoiture.fromTo("#VoitureFilet02", 
                    { opacity: 0, },
                    { duration: 0.7, opacity: 1 })
                
                    Pcpvoiture.fromTo("#VoitureFilet03", 
                    { opacity: 0, },
                    { duration: 0.7, opacity: 1 })
                
                    Pcpvoiture.fromTo("#VoitureFilet04", 
                    { opacity: 0, },
                    { duration: 0.7, opacity: 1 })
                
                    Pcpvoiture.fromTo("#VoitureFilet05", 
                    { opacity: 0, },
                    { duration: 0.7, opacity: 1 })
                
                    Pcpvoiture.fromTo("#VoitureElements01", 
                    { opacity: 0, },
                    { duration: 0.7, opacity: 1 })
            
                    Pcpvoiture.fromTo("#VoitureElements02", 
                    { opacity: 0, },
                    { duration: 0.7, opacity: 1 })
            
                    Pcpvoiture.fromTo("#VoitureElements03", 
                    { opacity: 0, },
                    { duration: 0.7, opacity: 1 },"+=3")
            
                    Pcpvoiture.fromTo("#VoitureElements04", 
                        { opacity: 0, },
                        { duration: 0.7, opacity: 1 })

                    Pcpvoiture.fromTo("#VoitureElements05", 
                    { opacity: 0, },
                    { duration: 0.7, opacity: 1 })  
                    
                    Pcpvoiture.fromTo("#VoitureElements06", 
                        { opacity: 0, },
                        { duration: 0.7, opacity: 1 }) 

                    Pcpvoiture.fromTo("#VoitureTrait", 
                    { opacity: 1, },
                    { duration: 0.7, opacity: 0 }) 

                    Pcpvoiture.fromTo("#VoitureElements07", 
                    { opacity: 0, },
                    { duration: 0.7, opacity: 1 }) 
            
            }



let chat = document.querySelector('.illu__chat');

if  ((chat)) {
    const Pcchat = gsap.timeline({repeat:-1, repeatDelay: 10});

   


        Pcchat.fromTo("#chatElement00", 
            { opacity: 0, },
            { duration: 0.7, opacity: 1 })
    
        Pcchat.fromTo("#chatElement01", 
        { opacity: 0, },
        { duration: 0.7, opacity: 1 })
    
        Pcchat.fromTo("#chatElement08", 
        { opacity: 0, },
        { duration: 0.7, opacity: 1 })
    
        Pcchat.fromTo("#chatElement07", 
        { opacity: 0, },
        { duration: 0.7, opacity: 1 })
    
        Pcchat.fromTo("#chatElement06", 
        { opacity: 0, },
        { duration: 0.7, opacity: 1 })
    
        Pcchat.fromTo("#chatElement05", 
        { opacity: 0, },
        { duration: 0.7, opacity: 1 })

        Pcchat.fromTo("#chatElement04", 
        { opacity: 0, },
        { duration: 0.7, opacity: 1 })

        Pcchat.fromTo("#chatElement03", 
        { opacity: 0, },
        { duration: 0.7, opacity: 1 },"+=3")

        Pcchat.fromTo("#chatElement00", 
            { opacity: 1, },
            { duration: 0.7, opacity: 0 })

        Pcchat.fromTo("#chatElement02", 
        { opacity: 0, },
        { duration: 0.7, opacity: 1 },"+=2")   

}




let piment = document.querySelector('.illu__piments');

if  ((piment)) {
    const Pcpiment = gsap.timeline({repeat:-1, repeatDelay: 10});

   
        Pcpiment.fromTo("#PimentsElements00", 
            { opacity: 0, },
            { duration: 0.7, opacity: 1 })
    
        Pcpiment.fromTo("#PimentsElements01", 
        { opacity: 0, },
        { duration: 0.7, opacity: 1 })
    
        Pcpiment.fromTo("#PimentsElements02", 
        { opacity: 0, },
        { duration: 0.7, opacity: 1 })
    
        Pcpiment.fromTo("#PimentsElements03", 
        { opacity: 0, },
        { duration: 0.7, opacity: 1 })
    
        Pcpiment.fromTo("#PimentsElements05", 
        { opacity: 0, },
        { duration: 0.7, opacity: 1 })
    
        Pcpiment.fromTo("#PimentsElements07", 
        { opacity: 0, },
        { duration: 0.7, opacity: 1 })

        Pcpiment.fromTo("#PimentsElements04", 
        { opacity: 0, },
        { duration: 0.7, opacity: 1 })

        Pcpiment.fromTo("#PimentsElements06", 
        { opacity: 0, },
        { duration: 0.7, opacity: 1 },"+=3")

        Pcpiment.fromTo("#PimentsElements08", 
            { opacity: 0, },
            { duration: 0.7, opacity: 1 })  

}


//page chaussure

let chaussu = document.querySelector('.illu__chaussure');

if  ((chaussu)) {

    const chaussMob = gsap.timeline({repeat:-1, repeatDelay: 5});

    chaussMob.fromTo("#etiquette02", 
    { opacity: 0, },
    { duration: 0.7, opacity: 1 },"+=2")

    chaussMob.fromTo("#etiquette01", 
    { opacity: 0, },
    { duration: 0.7, opacity: 1 })

    chaussMob.fromTo("#haut", 
    { opacity: 0, },
    { duration: 0.7, opacity: 1 })

    chaussMob.fromTo("#trou", 
    { opacity: 0, },
    { duration: 0.7, opacity: 1 })

    chaussMob.fromTo("#dur01", 
    { opacity: 0, },
    { duration: 0.7, opacity: 1 })

    chaussMob.fromTo("#nike", 
    { opacity: 0, },
    { duration: 0.7, opacity: 1 })

    chaussMob.fromTo("#bleue", 
    { opacity: 0, },
    { duration: 0.7, opacity: 1 })

    chaussMob.fromTo("#semelleB", 
    { opacity: 0, },
    { duration: 0.7, opacity: 1 })

    chaussMob.fromTo("#semelle", 
    { opacity: 0, },
    { duration: 0.7, opacity: 1 })

    chaussMob.fromTo("#etiquette03", 
    { opacity: 0, },
    { duration: 0.7, opacity: 1 },"+=2")

    chaussMob.fromTo("#dur02", 
    { opacity: 0, },
    { duration: 0.7, opacity: 1 })

    chaussMob.fromTo("#airrouge", 
    { opacity: 0, },
    { duration: 0.7, opacity: 1 })

    chaussMob.fromTo("#bulle01", 
    { opacity: 0, },
    { duration: 0.7, opacity: 1 })

    chaussMob.fromTo("#trait", 
    { opacity: 1 },
    { duration: 2, opacity: 0 })

    chaussMob.fromTo("#detail", 
    { opacity: 0, },
    { duration: 1, opacity: 1 },"+=2")

    chaussMob.fromTo("#ombre", 
    { opacity: 0, },
    { duration: 1, opacity: 1 },"+=5")

}


// page fiche {repeat:-1}

let fiche = document.querySelector('.illu__fiche');

if  ((fiche)) {


    const TelFiche = gsap.timeline({repeat:-1,duration: 2 });

    TelFiche.from(".img__fiche--logo", {
        opacity:0,
        duration:1,
        y: 70,
    });
    TelFiche.to(".img__fiche--logo", {
        opacity:0,
        duration:1,
    },"+=5");
    
    TelFiche.from(".img__fiche--gabarit", {
        opacity:0,
        duration:1,
        y: 70,
    });
    TelFiche.to(".img__fiche--gabarit", {
        opacity:0,
        duration:1,
    },"+=5");   

    TelFiche.from(".img__fiche--txt", {
        opacity:0,
        duration:1,
        y: 70
    });
    TelFiche.to(".img__fiche--txt", {
        opacity:0,
        duration:1,
    },"+=5");

    TelFiche.from(".img__fiche--style", {
        opacity:0,
        duration:1,
        y: 70
    });
    TelFiche.to(".img__fiche--style", {
        opacity:0,
        duration:1,
    },"+=5");

    TelFiche.from(".img__fiche--final", {
        opacity:0,
        duration:1,
        y: 70
    });
    TelFiche.to(".img__fiche--final", {
        opacity:0,
        duration:1,
     },"+=5");


}


let arbre = document.querySelector('.illu__arbre');

if  ((arbre)) {


    const PcArbre = gsap.timeline({repeat:-1,duration: 2 });

    PcArbre.from(".img__arbre--tronc", {
        opacity:0,
        duration:1,
        y: 70,
    });
    PcArbre.to(".img__arbre--tronc", {
        opacity:0,
        duration:1,
    },"+=5");
    
    PcArbre.from(".img__arbre--feuille02", {
        opacity:0,
        duration:1,
        y: 70,
    });
    PcArbre.to(".img__arbre--feuille02", {
        opacity:0,
        duration:1,
    },"+=5");   

    PcArbre.from(".img__arbre--feuille03", {
        opacity:0,
        duration:1,
        y: 70
    });
    PcArbre.to(".img__arbre--feuille03", {
        opacity:0,
        duration:1,
    },"+=5");

    PcArbre.from(".img__arbre--feuille01", {
        opacity:0,
        duration:1,
        y: 70
    });
    PcArbre.to(".img__arbre--feuille01", {
        opacity:0,
        duration:1,
    },"+=5");

    PcArbre.from(".img__arbre--arbre2", {
        opacity:0,
        duration:1,
        y: 70
    });
    PcArbre.to(".img__arbre--arbre2", {
        opacity:0,
        duration:1,
     },"+=5");

     PcArbre.from(".img__arbre--arbre", {
        opacity:0,
        duration:1,
        y: 70
    });
    PcArbre.to(".img__arbre--arbre", {
        opacity:0,
        duration:1,
     },"+=5");

}